<template>
  <div>
    <div class="data-header" style="height: 60px">
      <span class="data-min-title">{{cnName}}</span>
    </div>
    <div class="data-content-tabel">
      <div class="left-tabel">
        <div class="table-title">
          <div class="plate-number">车牌号</div>
          <div class="alarm-name">信息类型</div>
          <div class="alarm-time">时间</div>
        </div>
        <vue-seamless :data="myData.eventList" :class-option="optionHover" class="auto-scorll-table">
          <ul class="seamless-warp">
            <li v-for="(item, index) in myData.eventList" :key="index" class="seamless-warp-li">
              <div class="plate-number1">{{ item.plateNumber }}</div>
              <div class="alarm-name1">{{ item.alarmName }}</div>
              <div class="alarm-time1">{{ item.time|str2ymdhm }}</div>
            </li>
          </ul>
        </vue-seamless>
      </div>
      <el-carousel indicator-position="none" :interval="4000" type="card" height="calc(23vh - 100px)" class="carousel">
        <el-carousel-item v-for="(item, index) in myData.imageList" :key="index">
          <div class="image-bg">
            <img :src="item" alt="" class="image-item">
          </div>
          <!-- <h3 class="medium">{{ item }}</h3> -->
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>
<script>
import mixin from "./mixin-all";
import image1 from "@/assets/images/ailivingtech_bg.png"
import image2 from "@/assets/images/ailivingtech_bg.png"
import image3 from "@/assets/images/ailivingtech_bg.png"
import vueSeamless from 'vue-seamless-scroll'
export default {
  mixins: [mixin],
  components: {
    vueSeamless
  },
  data() {
    return {      
      //↓/////钩子字段//////////////
      name: "ssclsj",
      cnName: "实时车辆事件",
      api: "/sanitation/dataCloudService/getRealTimeCarEvent",
      isMock:false,
      comData: [],
      //↑////钩子字段//////////////
      debug:0,
    };
  },
  mounted() {
    let self = this;
    if(self.isMock){
      self.comData = getMockData();
      let timer = setInterval(async () => {
        self.comData = getMockData();
      }, 6000);
      
      self.$once("hook:beforeDestory", () => {
        clearInterval(timer);
        timer = null;
      });
    }
  },
  
  computed: {
    myData() {
      return this.comData
    },
    optionHover () {
      return {
        step: 0.2,
        limitMoveNum: 5,
        openWatch: true, 
        waitTime: 3000
      }
    }
  }
};
function getMockData() {
  return {
    eventList: [
      {
        plateNumber: '湘A12345',
        alarmName: '测试数据',
        time: 1594286325
      },
      {
        plateNumber: '湘A12345',
        alarmName: '测试数据',
        time: 1594286325
      },
      {
        plateNumber: '湘A12345',
        alarmName: '测试数据',
        time: 1594286325
      },
      {
        plateNumber: '湘A12345',
        alarmName: '测试数据',
        time: 1594286325
      },
      {
        plateNumber: '湘A12345',
        alarmName: '测试数据',
        time: 1594286325
      },
      {
        plateNumber: '湘A12345',
        alarmName: '测试数据',
        time: 1594286325
      },
    ],
    imageList: [image1,image2,image3]
  }
};
</script>
<style scoped lang='sass'>
.data-content-tabel
  width: 100%
  height: calc(45vh - 145px)
  padding: 0 24px
  .left-tabel
    height: calc(22vh - 75px)
    width: 100%
    text-align: center
    .table-title
      width: 100%
      background: rgba(8,49,126,1)
      border-top: 1px solid rgba(8,155,254,1)
      font-size: 12px
      padding: 10px
      font-weight: bold
      .plate-number 
        width: 30%
        display: inline-block
        vertical-align: middle
      .alarm-name
        width: 30%
        display: inline-block
        vertical-align: middle
      .alarm-time
        width: 40%
        display: inline-block
        vertical-align: middle
    .auto-scorll-table
      width: 100%
      height: calc(22vh - 100px)
      overflow: hidden
      .seamless-warp
        width: 100%
        height: 100%
        font-size: 12px
        font-weight: bold
        color: rgba(255, 255, 255, 0.8)
        li:nth-of-type(even)
          background: rgba(16,112,217,0.3)
        .seamless-warp-li
          padding: 8px 10px
          .plate-number1 
            width: 30%
            display: inline-block
            vertical-align: middle
          .alarm-name1
            width: 30%
            display: inline-block
            vertical-align: middle
          .alarm-time1
            width: 40%
            display: inline-block
          vertical-align: middle
  .carousel
    margin: 15px 0
    .image-bg
      width: 100%
      height: calc(23vh - 100px)
      background: url('../../../assets/images/cloud-hw/shipin-kuang2.png') no-repeat top / cover
      background-size: 100% 100%
      .image-item
        width: 100%
        height: 100%
        padding: 4px
        object-fit:cover

/deep/ .el-table 
        td
            border-bottom: 0px solid #EBEEF5

            &:hover
                background-color: transparent

        tr
            background-color: transparent

            &:hover > td
                background-color: #212e3e !important

        th.gutter
            background: #07317E

        &::before
            height: 0px
</style>